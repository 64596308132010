import React from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { Hero, ClientServices } from "../../containers"
import ClientServicesCrumbs from "../../constants/BreadCrumbs/ClientServicesCrumbs"
import { BreadCrumb } from "../../components"

const ClientServicesPage = ({ data }) => {
  const image = getImage(
    data.strapiClients.image.localFile.childImageSharp.gatsbyImageData
  )
  //console.log("ClientServicesPage - data, text:", data.strapiClients.text)
  return (
    <section>
      <Hero image={image} title="Managed Client Services" />
      <BreadCrumb crumbs={ClientServicesCrumbs} />
      <ClientServices
        text={data.strapiClients.text}
        title={data.strapiClients.title}
      />
    </section>
  )
}

export default ClientServicesPage

export const query = graphql`
  {
    strapiClients {
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(formats: AUTO, placeholder: BLURRED)
          }
        }
      }
      title
      text
    }
  }
`
