const ClientServicesCrumbs = [
  {
    id: 1,
    title: "Home",
    slug: "/",
  },
  {
    id: 2,
    title: "Services",
    slug: "/services",
  },
  {
    id: 4,
    title: "Managed Services",
    slug: "/services/clientServices",
  },
]

export default ClientServicesCrumbs
